export default function CtaTab(el, {
	activeClass,
}) {
	const primaryHero = document.querySelector('.primary-hero');
	const footer = document.querySelector('.footer');
	
	const obCallback = (payload) => {
		payload.forEach((e) => {
			if (e.target.className === 'primary-hero') {
				if (e.isIntersecting) {
					el.classList.remove(activeClass);
				} else {
					el.classList.add(activeClass);
				}
			}

			if (e.target.className === 'footer') {
				if (e.isIntersecting) {
					el.classList.remove(activeClass);
				} else {
					el.classList.add(activeClass);
				}
			}
		});
	}

	const primaryHeroObserver = new IntersectionObserver(obCallback);
	const footerObserver = new IntersectionObserver(obCallback);

	primaryHeroObserver.observe(primaryHero);
	footerObserver.observe(footer);
}