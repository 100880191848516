export default function Button(el, {
	modifierClass,
}) {
	const root = document.documentElement;
	let btnWidth = 0;

	// **************************************************

	el.addEventListener('mouseenter', (event) => {
		modifierClass === 'button--inline' ? btnWidth = event.target.offsetWidth - 38 : btnWidth = (event.target.offsetWidth - 21) * 2;
		root.style.setProperty('--btn-width', `${btnWidth}px`);
		
		event.target.classList.remove('button-animate-out');
		event.target.classList.add('button-animate-in');
	});

	el.addEventListener('mouseleave', (event) => {
		event.target.classList.remove('button-animate-in');
		event.target.classList.add('button-animate-out');
	});
}
