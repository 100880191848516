export default function QuickFacts(el, {
	numColumnClass,
}) {
	const numHeight = getComputedStyle(document.documentElement).getPropertyValue("--num-height");
	const numColumns = el.querySelectorAll(`.${numColumnClass}`);

	const spinnyNumbers = () => {
		numColumns.forEach((numColumn) => {
			const targetNum = numColumn.dataset.numTarget * numHeight;
			numColumn.style.transform = `translateY(-${targetNum}px)`;
		});
	}

	const onIntersection = (entries) => {
		for (const entry of entries) {
			if (entry.isIntersecting) {
				spinnyNumbers();
			}
		}
	};
	  
	const observer = new IntersectionObserver(onIntersection);
	observer.observe(document.querySelector('.quick-facts__facts-wrapper ul'));
}