import Swiper from 'swiper';
import { Navigation, EffectFade, Keyboard } from 'swiper/modules';

export default function MediaCarousel(el, {
	swiperClass,
}) {
	const root = document.documentElement;
	const assetHeight = el.querySelector('.media-carousel__asset').offsetHeight + 40;
	const prev = el.querySelector('.media-carousel__nav-prev');
	const next = el.querySelector('.media-carousel__nav-next');
	
	root.style.setProperty('--nav-y-pos', `${assetHeight}px`);

	const swiper = new Swiper(`.${swiperClass}`, {
		modules: [
			Navigation,
			EffectFade,
			Keyboard,
		],
		navigation: {
            prevEl: prev,
            nextEl: next,
        },
		keyboard: {
			enabled: true,
		},
		autoHeight: true,
		effect: 'fade',
		fadeEffect: {
			crossFade: true,
		},
	});
}