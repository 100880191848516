import Swiper from 'swiper';
import { Navigation, EffectFlip, EffectFade, Keyboard } from 'swiper/modules';

export default function TestimonialCarousel(el, {
	swiperAssetsClass,
	swiperTestimonialsClass,
}) {
	const prev = el.querySelector('.testimonial-carousel__nav-prev');
	const next = el.querySelector('.testimonial-carousel__nav-next');
	
	const swiperAssets = new Swiper(`.${swiperAssetsClass}`, {
		modules: [
			Navigation,
			EffectFade,
			Keyboard
		],
		navigation: {
			nextEl: next,
			prevEl: prev,
		},
		keyboard: {
			enabled: true,
		},
		effect: 'fade',
		fadeEffect: {
			crossFade: true,
		},
		allowTouchMove: false,
	});

	const swiperTestimonials = new Swiper(`.${swiperTestimonialsClass}`, {
		modules: [
			Navigation,
			EffectFlip,
			Keyboard,
		],
		navigation: {
			nextEl: next,
			prevEl: prev,
		},
		keyboard: {
			enabled: true,
		},
		effect: 'flip',
		allowTouchMove: false,
		autoHeight: true,
	});
}